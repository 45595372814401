import React, {createRef, FC, memo, SyntheticEvent, useCallback} from 'react';

import {logger} from 'app/sentry-logger';

import {Button} from '../button';

interface Props {
    label?: string;
    id: string;
    testId?: string;
    disabled?: boolean;
    loading?: boolean;
    onUpload(file: File): void;
}

const UploadButtonBase: FC<Props> = props => {
    const {label, id, disabled, loading, testId, onUpload} = props;

    const onFileChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
        const elem = event.currentTarget;
        if (!elem.files?.[0]) {
            const err = new Error('Missing file on input.');
            logger.logError(err);
            throw err;
        }
        const file = elem.files.item(0);
        if (file) {
            onUpload(file);
            elem.value = '';
        }
    }, [onUpload]);

    const labelId = disabled || loading ? '' : id;

    const ref = createRef<HTMLInputElement>();

    const onClick = useCallback(() => {
        if (ref.current) {
            ref.current.click();
        }
    }, [ref]);

    return (
        <span className="mr-1">
            <label
                htmlFor={labelId}
                onClick={onClick}
                className="mb-0"
            >
                <Button
                    visuals="primary"
                    loading={loading}
                    icon="importOutlined"
                >
                    {label || 'Nahrát'}
                </Button>
            </label>
            <input
                data-test-id={testId}
                type="file"
                className="d-none"
                ref={ref}
                id={id}
                onChange={onFileChange}
            />
        </span>
    );
};

export const UploadButton = memo(UploadButtonBase);
