import {classNames, usePrevious} from '@fl/cmsch-fe-library';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {initial} from 'lodash/fp';
import React, {useCallback, useEffect, useState, memo, ReactNode, FC} from 'react';
import {Link} from 'react-router-dom';
import {opt, optEmptyArray} from 'ts-opt';

import {Role} from 'api/gen/Role';
import {useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';
import {ICONS} from 'common/buttons/icons';
import {MenuItem} from 'common/layout/types/menu-item';

import styles from './styles.sass';

interface Props {
    menuItems: Array<MenuItem>;
    locationPath: string;
    isUserLoading: boolean;
    userRole: Role;
    isSideMenuCollapsed?: boolean;

    onMenuItemClick?(): void;
}

interface RenderMenuItemsProps {
    menuItems: Array<MenuItem>;
    userRole: Role;
}

const buildMenuItems = ({menuItems, userRole}: RenderMenuItemsProps): Array<ItemType> =>
    menuItems.map((menuItem: MenuItem) => {
        const {label, key, path, icon, subMenuItems, hidden, permissions} = menuItem;

        const menuItemLabel: ReactNode = (
            <div className={styles.sideMenuLabel}>
                {icon && ICONS[icon]}
                {label}
            </div>
        );

        return optEmptyArray(subMenuItems?.filter(x => !x.hidden && x.permissions.includes(userRole))).caseOf<ItemType>(
            sbItems => ({
                key,
                label: menuItemLabel,
                children: buildMenuItems({menuItems: sbItems, userRole}),
                title: null,
            }),
            () => !hidden && permissions.includes(userRole) ? ({
                key,
                label: (
                    <Link to={opt(path).orCrash('leaf menu item has no path')}>
                        {menuItemLabel}
                    </Link>
                ),
                title: undefined,
            }) : null,
        );
    });

const getKeysForPath = (menuItems: Array<MenuItem>, path: string): Array<string> | undefined => {
    for (const item of menuItems) {
        if (item.path === path) {
            return [item.key];
        } else if (item.subMenuItems) {
            const subMenuKeys = getKeysForPath(item.subMenuItems, path);
            if (subMenuKeys) {
                return [item.key, ...subMenuKeys];
            }
        }
    }

    return undefined;
};

const SideMenuBase: FC<Props> = props => {
    const {
        locationPath,
        menuItems,
        isUserLoading,
        userRole,
        isSideMenuCollapsed,
        onMenuItemClick,
    } = props;

    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    const [openKeys, setOpenKeys] = useState<Array<string>>([]);

    const {t} = useOurTranslation('user/login');

    const prevMenuItems = usePrevious(menuItems);
    const prevLocationPath = usePrevious(locationPath);
    useEffect(() => {
        const pathKeys = getKeysForPath(menuItems, locationPath) ?? [];
        setSelectedKeys(pathKeys);
        setOpenKeys(initial(pathKeys));
    }, [menuItems, locationPath, prevMenuItems, prevLocationPath]);

    const handleMenuItemClick = useCallback(() => {
        if (onMenuItemClick) {
            onMenuItemClick();
        }
    }, [onMenuItemClick]);

    const handleOpenChange = useCallback((value: Array<string>) => {
        setOpenKeys(value);
    }, []);

    return (
        <div className={classNames(styles.menuContainer, styles.sideMenu)}>

            {isUserLoading
                ? (
                    <div className={styles.loadingHeader}>{t('isUserLoading')}...</div>
                )
                : (
                    <div className={styles.antMenu}>
                        <Ant.Menu
                            mode="inline"
                            theme="dark"
                            selectedKeys={selectedKeys}
                            openKeys={isSideMenuCollapsed ? [] : openKeys}
                            onClick={handleMenuItemClick}
                            onOpenChange={handleOpenChange}
                            items={buildMenuItems({menuItems, userRole})}
                            _internalDisableMenuItemTitleTooltip
                        />
                    </div>
                )}
        </div>
    );
};

export const SideMenu = memo(SideMenuBase);
