import React, {FC, memo} from 'react';

import {environmentLogoMap} from '../app-logo';

import styles from './styles.sass';

const CmschLogoBase: FC = () => (
    <div
        className={styles.cmschLogo}
        // eslint-disable-next-line react/no-danger,@typescript-eslint/naming-convention
        dangerouslySetInnerHTML={{__html: environmentLogoMap.development}}
        data-test-id="cmschLogo"
    />
);

export const CmschLogo = memo(CmschLogoBase);
