// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-buttons-components-button-___styles__antBtnInlineFlex___NyxOP{display:inline-flex !important;align-items:center}.src-common-buttons-components-button-___styles__smallButtonBiggerIcon___N0or3{font-size:16px !important}.src-common-buttons-components-button-___styles__smallButtonBiggerIcon___N0or3>*{font-size:18px !important}.src-common-buttons-components-button-___styles__autoSize___UxJcT{width:unset !important;height:unset !important}.src-common-buttons-components-button-___styles__noPadding___QI1B5{padding:0 !important}.src-common-buttons-components-button-___styles__noLineHeight___mWgZd{line-height:0 !important}.src-common-buttons-components-button-___styles__inputAction___fLHTN{background-color:#ffc107 !important;color:#fff !important}.src-common-buttons-components-button-___styles__inputAction___fLHTN:disabled{background:#f5f5f5 !important;color:rgba(0,0,0,.25) !important}", "",{"version":3,"sources":["webpack://./src/common/buttons/components/button/styles.sass"],"names":[],"mappings":"AAEA,0EACI,8BAAA,CACA,kBAAA,CAEJ,+EACI,yBAAA,CAEA,iFACI,yBAAA,CAER,kEACI,sBAAA,CACA,uBAAA,CAEJ,mEACI,oBAAA,CAEJ,sEACI,wBAAA,CAEJ,qEACI,mCAAA,CACA,qBAAA,CAEA,8EACI,6BAAA,CACA,gCAAA","sourcesContent":["@import '../../../../styles/variables'\n\n.antBtnInlineFlex\n    display: inline-flex !important\n    align-items: center\n\n.smallButtonBiggerIcon\n    font-size: 16px !important\n\n    > *\n        font-size: 18px !important\n\n.autoSize\n    width: unset !important\n    height: unset !important\n\n.noPadding\n    padding: 0 !important\n\n.noLineHeight\n    line-height: 0 !important\n\n.inputAction\n    background-color: $warning !important\n    color: $white !important\n\n    &:disabled\n        background: $ant-background-disabled !important\n        color: $ant-text-disabled !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"antBtnInlineFlex": "src-common-buttons-components-button-___styles__antBtnInlineFlex___NyxOP",
	"smallButtonBiggerIcon": "src-common-buttons-components-button-___styles__smallButtonBiggerIcon___N0or3",
	"autoSize": "src-common-buttons-components-button-___styles__autoSize___UxJcT",
	"noPadding": "src-common-buttons-components-button-___styles__noPadding___QI1B5",
	"noLineHeight": "src-common-buttons-components-button-___styles__noLineHeight___mWgZd",
	"inputAction": "src-common-buttons-components-button-___styles__inputAction___fLHTN"
};
export default ___CSS_LOADER_EXPORT___;
