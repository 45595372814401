import {TooltipPlacement} from 'antd/lib/tooltip';
import React, {memo, FC} from 'react';

import {useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';

import {Button, ButtonProps} from '../button';

interface Props extends ButtonProps {
    confirmTitle?: string;
    confirmPlacement?: TooltipPlacement;
    cancelText?: string;
    okText?: string;
    onCancelClick?(): void;
}

const PopconfirmButtonBase: FC<Props> = props => {
    const {
        children,
        type,
        disabled,
        visuals,
        tooltip,
        icon,
        loading,
        block,
        size,
        ghost,
        danger,
        smallButtonBiggerIcon,
        autoSize,
        noPadding,
        noLineHeight,
        bottomMargin,
        confirmTitle,
        confirmPlacement,
        cancelText,
        okText,
        onClick,
        onCancelClick,
        testId,
    } = props;

    const {t} = useOurTranslation('common');

    return (
        <Ant.Popconfirm
            title={confirmTitle ?? t('confirmAction')}
            cancelText={cancelText ?? t('cancel')}
            onCancel={onCancelClick}
            okText={okText ?? t('confirm')}
            onConfirm={onClick}
            placement={confirmPlacement}
            arrowPointAtCenter={Boolean(confirmPlacement)}
        >
            <Button
                visuals={visuals}
                disabled={disabled}
                tooltip={tooltip}
                icon={icon}
                type={type}
                loading={loading}
                block={block}
                size={size}
                ghost={ghost}
                danger={danger}
                autoSize={autoSize}
                noPadding={noPadding}
                noLineHeight={noLineHeight}
                bottomMargin={bottomMargin}
                smallButtonBiggerIcon={smallButtonBiggerIcon}
                testId={testId}
            >
                {children}
            </Button>
        </Ant.Popconfirm>
    );
};

export const PopconfirmButton = memo(PopconfirmButtonBase);
