import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {useOurTranslation} from 'app/translations';

import {Loader} from '../loader';

import styles from './styles.sass';

interface Props {
    children?: ReactNode;
    loading?: boolean;
    showContentWhileLoading?: boolean;
    centerContent?: boolean;
    text?: string;
    inline?: boolean;
    noBackground?: boolean;
    testId?: string;
}

const LoaderWrapperBase: FC<Props> = props => {
    const {children, loading, showContentWhileLoading, centerContent, inline, noBackground, testId, text} = props;

    const {t} = useOurTranslation('common');

    return (
        <div className={classNames(styles.wrapper, centerContent && 'd-flex flex-column align-items-center')}>
            <Loader
                size="large"
                show={loading}
                noBackground={noBackground}
                testId={testId}
                text={text ? text : t('dataLoading')}
                inline={inline}
            />
            {(!loading || showContentWhileLoading) && children}
        </div>
    );
};

export const LoaderWrapper = memo(LoaderWrapperBase);
