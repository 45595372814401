import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';
import {Link} from 'react-router-dom';

import styles from './styles.sass';

interface Props {
    appLogo: ReactNode;
    actionIcons: ReactNode;
    link?: string;
}

const SmallMenuBase: FC<Props> = props => {
    const {appLogo, actionIcons, link = '/'} = props;

    return (
        <div className="position-relative">
            <div className={classNames(styles.smallMenuLogo, 'position-absolute')}>
                <Link to={link}>
                    {appLogo}
                </Link>
            </div>
            <div className="d-flex">
                <div className="ml-auto">
                    {actionIcons}
                </div>
            </div>
        </div>
    );
};

export const SmallMenu = memo(SmallMenuBase);
