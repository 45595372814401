import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, PropsWithChildren} from 'react';

import styles from './styles.sass';

interface Props {
    autoWidth?: boolean;
}

const PanelBase: FC<PropsWithChildren<Props>> = props => {
    const {children, autoWidth} = props;
    const className = classNames(
        autoWidth ? 'w-auto' : 'w-100',
        styles.panel,
        'mb-2',
    );

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export const Panel = memo(PanelBase);
