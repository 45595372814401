// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-layout-components-modal-___styles__modal___pEpiM .ant-modal-close-x{display:flex}.src-common-layout-components-modal-___styles__modal___pEpiM .ant-modal-close-x .anticon-close{margin:auto}@media(max-width: 575px){.src-common-layout-components-modal-___styles__modal___pEpiM .ant-modal-title{margin-right:20px}}", "",{"version":3,"sources":["webpack://./src/common/layout/components/modal/styles.sass"],"names":[],"mappings":"AAIQ,gFACI,YAAA,CACA,+FACI,WAAA,CAChB,yBAGY,8EACI,iBAAA,CAAA","sourcesContent":["@import '../../../../styles/variables'\n\n.modal\n    \\:global\n        .ant-modal-close-x\n            display: flex\n            .anticon-close\n                margin: auto\n@media (max-width: $screen-sm - 1)\n    .modal\n        \\:global\n            .ant-modal-title\n                margin-right: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "src-common-layout-components-modal-___styles__modal___pEpiM"
};
export default ___CSS_LOADER_EXPORT___;
