// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-layout-components-card-___styles__titleSizeBig___yx8Mg .ant-card-head-title{font-size:24px}.src-common-layout-components-card-___styles__smallSidePadding___Eggou .ant-card-body{padding-left:5px;padding-right:5px;padding-bottom:5px}.src-common-layout-components-card-___styles__smallSidePadding___Eggou .ant-card-head{padding-left:5px;padding-right:5px}@media(max-width: 575px){.src-common-layout-components-card-___styles__card___fTfYZ .ant-card-body{padding-left:0;padding-right:0}.src-common-layout-components-card-___styles__titleSizeBig___yx8Mg .ant-card-head-title{font-size:14px;line-height:1.2;white-space:normal}}", "",{"version":3,"sources":["webpack://./src/common/layout/components/card/styles.sass"],"names":[],"mappings":"AAGK,wFACG,cAAA,CAEH,sFACG,gBAAA,CACA,iBAAA,CACA,kBAAA,CACH,sFACG,gBAAA,CACA,iBAAA,CAER,yBAES,0EACG,cAAA,CACA,eAAA,CAEH,wFACG,cAAA,CACA,eAAA,CACA,kBAAA,CAAA","sourcesContent":["@import \"../../../../styles/variables\"\n\n.titleSizeBig\n    \\:global(.ant-card-head-title)\n        font-size: 24px\n.smallSidePadding\n    \\:global(.ant-card-body)\n        padding-left: 5px\n        padding-right: 5px\n        padding-bottom: 5px\n    \\:global(.ant-card-head)\n        padding-left: 5px\n        padding-right: 5px\n\n@media (max-width: $screen-sm - 1)\n    .card\n        \\:global(.ant-card-body)\n            padding-left: 0\n            padding-right: 0\n    .titleSizeBig\n        \\:global(.ant-card-head-title)\n            font-size: 14px\n            line-height: 1.2\n            white-space: normal\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleSizeBig": "src-common-layout-components-card-___styles__titleSizeBig___yx8Mg",
	"smallSidePadding": "src-common-layout-components-card-___styles__smallSidePadding___Eggou",
	"card": "src-common-layout-components-card-___styles__card___fTfYZ"
};
export default ___CSS_LOADER_EXPORT___;
