/* eslint-disable import/no-unused-modules */
import {ModalId} from 'app/setup/modal-id';

import {LoadingItemId} from '../types/loading-item-id';

export const layoutAction = {
    setModalVisibility: (id: ModalId, visibility: boolean) => ({
        type: 'layout/SET_MODAL_VISIBILITY',
        payload: {id, visibility},
    }) as const,
    setItemLoading: (id: LoadingItemId, isLoading: boolean) => ({
        type: 'layout/SET_ITEM_LOADING',
        payload: {id, isLoading},
    }) as const,
};

export type SetModalVisibilityAction = ReturnType<typeof layoutAction.setModalVisibility>;
export type SetItemLoadingAction = ReturnType<typeof layoutAction.setItemLoading>;

export type LayoutAction =
    | SetModalVisibilityAction
    | SetItemLoadingAction
;
