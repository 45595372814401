// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-layout-components-loader-wrapper-___styles__wrapper___pE11y{position:relative}", "",{"version":3,"sources":["webpack://./src/common/layout/components/loader-wrapper/styles.sass"],"names":[],"mappings":"AAAA,wEACI,iBAAA","sourcesContent":[".wrapper\n    position: relative\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "src-common-layout-components-loader-wrapper-___styles__wrapper___pE11y"
};
export default ___CSS_LOADER_EXPORT___;
