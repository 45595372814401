// eslint-disable-next-line @fl/relative-imports-in-index-files-only, no-restricted-imports
import * as Antd from 'antd';

export const Ant = Antd;
export const AntPopconfirm = Antd.Popconfirm;
export const AntTabs = Antd.Tabs;
export const AntLayout = Antd.Layout;
export const AntPageHeader = Antd.PageHeader;
export const AntEmpty = Antd.Empty;
export const AntSpin = Antd.Spin;
