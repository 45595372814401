import {classNames, useWindowDimensions} from '@fl/cmsch-fe-library';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {isUndefined} from 'lodash/fp';
import React, {createRef, FC, memo, ReactElement, ReactNode, RefObject, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {optEmptyArray} from 'ts-opt';

import {Role} from 'api/gen/Role';
import {Ant} from 'common/ant';
import {IconName, ICONS} from 'common/buttons/icons';
import {MenuItem} from 'common/layout/types/menu-item';

import styles from './styles.sass';

const spaceBeforeActionIcons = 50;

interface Props {
    menuItem: MenuItem;
    locationPath: string;
    userRole: Role;
    visible: boolean;
    actionIconsRef: RefObject<HTMLDivElement>;
    toggleOverflowedMenuItem(key: string): void;
}

const getLabel = (label: string, isSubMenu: boolean, icon?: IconName): ReactElement => (
    <div className={isSubMenu ? styles.subMenuLabel : styles.menuLabel}>
        {icon && ICONS[icon]}
        {label}
    </div>
);

export const isActive = (locationPath: string, path?: string, subMenuItems?: Array<MenuItem>): boolean =>
    (path && locationPath.startsWith(path)) || (subMenuItems || []).some(x =>
        (x.path && locationPath.startsWith(x.path)) || isActive(locationPath, path, x.subMenuItems),
    );

export const buildSubMenuItems = (
    menuItems: Array<MenuItem>,
    locationPath: string,
    userRole: Role,
): Array<ItemType> => (
    menuItems.map(x => optEmptyArray(x.subMenuItems?.filter(y => !y.hidden)).caseOf<ItemType>(
        y => y.some(z => !z.hidden && z.permissions.includes(userRole)) ? ({
            key: x.key,
            className: classNames(isActive(locationPath, x.path, x.subMenuItems) && styles.active, styles.item),
            label: getLabel(x.label, true, x.icon),
            children: buildSubMenuItems(y, locationPath, userRole),
        }) : null,
        () => (x.path && !x.hidden && x.permissions.includes(userRole)) ? ({
            key: x.key,
            className: classNames(isActive(locationPath, x.path, x.subMenuItems) && styles.active),
            label: (
                <Link to={x.path}>
                    {getLabel(x.label, true, x.icon)}
                </Link>
            ),
        }) : null,
    ))
);

const MainMenuItemBase: FC<Props> = props => {
    const {menuItem, locationPath, visible, actionIconsRef, userRole, toggleOverflowedMenuItem} = props;
    const {label, key, path, icon, subMenuItems} = menuItem;

    const ref = createRef<HTMLDivElement>();
    const {width, height} = useWindowDimensions();

    const menuItemLabel: ReactNode = getLabel(label, false, icon);
    const className = classNames(
        styles.menuItem,
        isActive(locationPath, path, subMenuItems) && styles.active,
        !visible && 'invisible',
    );

    useEffect(() => {
        const {width: actionIconsWidth} = actionIconsRef.current?.getBoundingClientRect() || {};
        const ourWidth = width - (actionIconsWidth || 0) - spaceBeforeActionIcons;
        const {right} = ref.current?.getBoundingClientRect() || {};
        if (!isUndefined(right) && ((right < ourWidth && !visible) || (right > ourWidth && visible))) {
            toggleOverflowedMenuItem(key);
        }
    }, [height, label, ref, visible, toggleOverflowedMenuItem, key, actionIconsRef, width]);

    const button = (
        <div
            className={className}
            key={key}
            ref={ref}
        >
            {path ? (
                <Link to={path}>
                    {menuItemLabel}
                </Link>
            ) : menuItemLabel}
        </div>
    );

    return optEmptyArray(subMenuItems).caseOf(
        sbItems => (
            <Ant.Dropdown
                overlay={(
                    <Ant.Menu items={buildSubMenuItems(sbItems, locationPath, userRole)} />
                )}
                placement="bottomLeft"
                arrow
            >
                {button}
            </Ant.Dropdown>
        ),
        () => button,
    );
};

export const MainMenuItem = memo(MainMenuItemBase);
