import {opt, Opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';
import {t as tt, TFunction} from 'app/translations';
import {MenuItem} from 'common/layout/types/menu-item';

// eslint-disable-next-line max-lines-per-function
export const getMenuItems = (
    user: Opt<UserDetails>,
    t: TFunction<'mainMenu'>,
): Array<MenuItem> => [
    {
        key: 'importKkut',
        label: t('importKkut'),
        path: '/import-kkut',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'importOutlined',
    },
    {
        key: 'breedings',
        label: t('breedings'),
        path: '/breedings',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'fileAddOutlined',
    },
    {
        key: 'plan',
        label: t('plan'),
        path: '/plan',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'fileDoneOutlined',
    },
    {
        key: 'measurements',
        label: t('measurements'),
        path: '/measurements',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'searchOutlined',
    },
    {
        key: 'adminPlans',
        label: t('adminPlans'),
        path: '/admin-plans',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'mobileFilled',
    },
    {
        key: 'bulls',
        label: t('bulls'),
        path: '/bulls',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'bullIcon',
        subMenuItems: [
            {
                key: 'bullsOverview',
                label: t('bullsOverview'),
                path: '/bulls',
                permissions: ['ADMIN', 'BONITER'],
                icon: 'bullIcon',
            },
            {
                key: 'bullsInvoicingOverview',
                label: t('bullsInvoicingOverview'),
                path: '/invoicing-documents',
                permissions: ['ADMIN'],
                icon: 'fileTextOutlined',
            },
        ],
    },
    {
        key: 'databaseOfMeasured',
        label: t('databaseOfMeasured'),
        path: '/database-of-measured',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'cowIcon',
    },
    {
        key: 'breederSuites',
        label: t('breederSuites'),
        path: '/breeder-suites',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'cowIcon',
    },
    {
        key: 'administration',
        label: t('administration'),
        path: '/districts',
        permissions: ['ADMIN', 'BONITER'],
        icon: 'settingFilled',
        subMenuItems: [
            {
                key: 'districts',
                label: t('districts'),
                path: '/districts',
                permissions: ['ADMIN', 'BONITER'],
            },
            {
                key: 'breeders',
                label: t('breeders'),
                path: '/breeders',
                permissions: ['ADMIN', 'BONITER'],
                subMenuItems: [
                    {
                        key: 'breeder-detail',
                        label: 'breeder-detail',
                        path: '/breeder-detail',
                        permissions: ['ADMIN', 'BONITER'],
                        hidden: true,
                    },
                    {
                        key: 'stable-detail',
                        label: 'stable-detail',
                        path: '/stable-detail',
                        permissions: ['ADMIN', 'BONITER'],
                        hidden: true,
                    },
                ],
            },
            {
                key: 'repairs',
                label: t('repairs'),
                path: '/repairs',
                permissions: ['ADMIN', 'BONITER'],
            },
            {
                key: 'sent-emails',
                label: t('sentEmails'),
                path: '/sent-emails',
                permissions: ['ADMIN', 'BONITER'],
            },
            {
                key: 'app-info',
                label: t('appInfo'),
                path: '/app-info',
                permissions: ['ADMIN', 'BONITER'],
            },
        ],
    },
    {
        key: 'user',
        label: user.map(x => `${x.firstName} ${x.lastName}`).orElse(''),
        permissions: ['ADMIN', 'BONITER'],
        icon: 'userIcon',
        subMenuItems: [
            {
                key: 'logOut',
                label: t('logOut'),
                path: '/logout',
                permissions: ['ADMIN', 'BONITER'],
                icon: 'logOutOutlined',
            },
        ],
    },
];

export interface PathRestriction {
    onlyForLoggedIn: boolean;
    userLacksPermissions: boolean;
}

export const getPathRestriction = (user: Opt<UserDetails>, path: string): PathRestriction => {
    const menuItems: Array<MenuItem> = [];

    const pushToMenuItems = (item: MenuItem): void => {
        menuItems.push(item);
        item.subMenuItems?.forEach(pushToMenuItems);
    };

    getMenuItems(user, tt('mainMenu')).forEach(x => {
        pushToMenuItems(x);
    });

    const menuItem = menuItems.find(x => x.path && path.startsWith(x.path));

    return opt(menuItem).map<PathRestriction>(x => ({
        onlyForLoggedIn: x.permissions.length > 0,
        userLacksPermissions: user.forAll(y => !x.permissions.includes(y.role)),
    })).orElse({
        onlyForLoggedIn: false,
        userLacksPermissions: false,
    });
};
