import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {AntSpin} from 'common/ant';

import styles from './styles.sass';

type LoaderSize = 'large';

export interface LoaderProps {
    show?: boolean;
    size?: LoaderSize;
    text?: string;
    inline?: boolean;
    noBackground?: boolean;
    testId?: string;
    centerPosition?: boolean;
}

const LoaderBase: FC<LoaderProps> = props => {
    const {show, noBackground, testId = 'loader', inline, size, text, centerPosition} = props;

    if (!show) {
        return null;
    }

    const className = classNames(
        styles.loader,
        inline && styles.loaderInline,
        size && styles[size],
        noBackground && styles.noBackground,
        centerPosition && styles.centerPosition,
    );

    return (
        <div
            className={className}
            data-test-id={testId}
        >
            <AntSpin size="large" />
            {text && (
                <h3 className="mt-3 mb-0">{text}</h3>
            )}
        </div>
    );
};

export const Loader = memo(LoaderBase);
