import {ModalId} from 'app/setup/modal-id';

import {LoadingItemId} from '../types/loading-item-id';

export interface LayoutState {
    modalStack: Array<ModalId>; // ordered list of opened modals, the last is the one currently visible
    loadingItems: Array<LoadingItemId>;
}

export const initialLayoutState: LayoutState = {
    modalStack: [],
    loadingItems: [],
};
