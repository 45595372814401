import {setItem} from '@fl/cmsch-fe-library';
import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {initialLayoutState, LayoutState} from './state';

const layoutStateO = O.optic<LayoutState>();

export const layoutReducer = (
    state: LayoutState = initialLayoutState,
    action: AppAction,
): LayoutState => {
    switch (action.type) {
        case 'layout/SET_MODAL_VISIBILITY': {
            const {id, visibility} = action.payload;

            return O.modify(layoutStateO.prop('modalStack'))(setItem(visibility)(id))(state);
        }

        case 'layout/SET_ITEM_LOADING': {
            const {id, isLoading} = action.payload;

            return O.modify(layoutStateO.prop('loadingItems'))(setItem(isLoading)(id))(state);
        }

        default:
            return state;
    }
};
