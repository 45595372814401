import React, {FC, memo, ReactNode} from 'react';

import {TFunction, useOurTranslation} from 'app/translations';

import {Button} from '../button';

interface Props {
    editing?: boolean;
    disabledSubmit?: boolean;
    disabledCancel?: boolean;
    submitTitle?: string;
    cancelTitle?: string;
    submitTooltip?: string;
    cancelTooltip?: string;
    withoutCancel?: boolean;
    valid?: boolean;
    submitting?: boolean;
    asyncValidating?: boolean;
    pristine?: boolean;
    submitCanBeDisabled?: boolean;
    extraButtons?: Array<ReactNode>;
    onCancel?(): void;
}

const getSubmitButtonTooltip = (
    t: TFunction<'common'>,
    valid?: boolean,
    submitting?: boolean,
    asyncValidating?: boolean,
    pristine?: boolean,
): string | undefined => {
    if (pristine) {
        return t('form.pristine');
    } else if (submitting) {
        return t('form.submitting');
    } else if (asyncValidating) {
        return t('form.asyncValidating');
    } else if (valid === false) {
        return t('form.notValid');
    } else {
        return undefined;
    }
};

const getCancelButtonTooltip = (
    t: TFunction<'common'>,
    submitting?: boolean,
    pristine?: boolean,
): string | undefined => {
    if (submitting) {
        return t('form.submitting');
    } else if (pristine) {
        return t('form.pristine');
    } else {
        return undefined;
    }
};

const FooterButtonsBase: FC<Props> = props => {
    const {
        editing,
        disabledSubmit,
        disabledCancel,
        submitTitle,
        cancelTitle,
        withoutCancel,
        submitting,
        submitTooltip,
        cancelTooltip,
        asyncValidating,
        valid,
        pristine,
        submitCanBeDisabled,
        extraButtons,
        onCancel,
    } = props;

    const {t} = useOurTranslation('common');

    return (
        <div className="row">
            <div className="col-12 d-flex justify-content-end">
                {extraButtons?.map((x, i) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className="mr-2"
                    >
                        {x}
                    </div>
                ))}
                {!withoutCancel && (
                    <div
                        className="mr-2"
                    >
                        <Button
                            key="back"
                            type="reset"
                            onClick={onCancel}
                            disabled={disabledCancel || submitting || pristine}
                            tooltip={cancelTooltip || getCancelButtonTooltip(t, submitting, pristine)}
                            testId="cancel-button"
                        >
                            {cancelTitle || t('cancel')}
                        </Button>
                    </div>
                )}
                <Button
                    key="submit"
                    visuals="primary"
                    type="submit"
                    loading={submitting}
                    disabled={
                        disabledSubmit || submitting || asyncValidating || (submitCanBeDisabled && (
                            valid === false || pristine
                        ))}
                    tooltip={submitTooltip || getSubmitButtonTooltip(
                        t,
                        valid,
                        submitting,
                        asyncValidating,
                        pristine,
                    )}
                    testId="submit-button"
                >
                    {submitTitle || t(editing ? 'edit' : 'save')}
                </Button>
            </div>
        </div>
    );
};

export const FooterButtons = memo(FooterButtonsBase);
