import {classNames} from '@fl/cmsch-fe-library';
import {ButtonType} from 'antd/lib/button';
import {ButtonHTMLType} from 'antd/lib/button/button';
import {SizeType} from 'antd/lib/config-provider/SizeContext';
import React, {memo, FC, ReactNode} from 'react';

import {Ant} from 'common/ant';
import {IconName, ICONS} from 'common/buttons/icons';

import styles from './styles.sass';

export type Visuals = ButtonType;

export interface ButtonProps {
    children?: ReactNode;
    visuals?: Visuals;
    icon?: IconName;
    disabled?: boolean;
    tooltip?: string;
    smallButtonBiggerIcon?: boolean;
    bottomMargin?: boolean;
    noLineHeight?: boolean;
    autoSize?: boolean;
    noPadding?: boolean;
    block?: boolean;
    ghost?: boolean;
    danger?: boolean;
    type?: ButtonHTMLType;
    size?: SizeType;
    loading?: boolean;
    testId?: string;
    onClick?(): void;
}

export interface Props extends ButtonProps {
    inputAction?: boolean;
}

const ButtonBase: FC<Props> = props => {
    const {
        onClick,
        visuals,
        disabled,
        tooltip,
        icon,
        smallButtonBiggerIcon,
        autoSize,
        noPadding,
        noLineHeight,
        bottomMargin,
        inputAction,
        type,
        children,
        loading,
        danger,
        ghost,
        size,
        block,
        testId,
    } = props;

    const className = classNames(
        smallButtonBiggerIcon && styles.smallButtonBiggerIcon,
        autoSize && styles.autoSize,
        noPadding && styles.noPadding,
        noLineHeight && styles.noLineHeight,
        bottomMargin && 'mb-2',
        inputAction && [styles.inputAction, 'ml-2'],
        visuals === 'link' && 'font-weight-bold',
    );

    return (
        <Ant.Button
            onClick={onClick}
            type={visuals}
            disabled={disabled}
            title={tooltip}
            icon={icon && ICONS[icon]}
            className={className}
            htmlType={type}
            loading={loading}
            block={block}
            size={size}
            ghost={ghost}
            danger={danger}
            data-test-id={testId}
        >
            {children !== undefined && (
                <span className={styles.antBtnInlineFlex}>
                    {children}
                </span>
            )}
        </Ant.Button>
    );
};

export const Button = memo(ButtonBase);
