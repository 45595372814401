import React, {FC, memo, ReactNode} from 'react';
import {useSelector} from 'react-redux';

import {ModalId} from 'app/setup/modal-id';
import {Ant} from 'common/ant';
import {simpleLayoutSelector} from 'common/layout/model/selector';

import styles from './styles.sass';

interface Props {
    modalId: ModalId;
    title: string;
    footer: ReactNode;
    children: ReactNode;
    dontDestroyOnClose?: boolean;
    testId?: string;
    width?: number;
    onCancel(): void;
}

const ModalBase: FC<Props> = props => {
    const {modalId, title, footer, children, dontDestroyOnClose, onCancel, width, testId} = props;

    const visible = useSelector(simpleLayoutSelector.isModalVisible(modalId));

    if (document.scrollingElement) {
        (document.scrollingElement as HTMLElement).style.overflow =
            visible ? 'hidden' : 'auto';
    }

    return visible ? (
        <Ant.Modal
            className={styles.modal} // eslint-disable-line react/forbid-component-props
            title={title}
            visible
            onCancel={onCancel}
            footer={footer}
            destroyOnClose={!dontDestroyOnClose}
            width={width}
            data-test-id={testId}
        >
            {children}
        </Ant.Modal>
    ) : null;
};

export const Modal = memo(ModalBase);
