// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common-layout-components-cmsch-logo-___styles__cmschLogo___EFSsf{width:200px;margin:0 auto 2rem auto}.src-common-layout-components-cmsch-logo-___styles__cmschLogo___EFSsf path{fill:#259b24 !important}", "",{"version":3,"sources":["webpack://./src/common/layout/components/cmsch-logo/styles.sass"],"names":[],"mappings":"AAEA,sEACI,WAAA,CACA,uBAAA,CACA,2EACI,uBAAA","sourcesContent":["@import '../../../../styles/variables'\n\n.cmschLogo\n    width: 200px\n    margin: 0 auto 2rem auto\n    path\n        fill: $primary !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cmschLogo": "src-common-layout-components-cmsch-logo-___styles__cmschLogo___EFSsf"
};
export default ___CSS_LOADER_EXPORT___;
