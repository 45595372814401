import {Location} from 'history';
import {last} from 'lodash/fp';
import {opt} from 'ts-opt';

import {ModalId} from 'app/setup/modal-id';
import {State} from 'app/setup/state';
import {LocationState} from 'app/user/types/location-state';

import {LoadingItemId} from '../types/loading-item-id';

export const simpleLayoutSelector = {
    isModalVisible: (id: ModalId) => ({layout}: State): boolean => last(layout.modalStack) === id,
    isItemLoading: (id: LoadingItemId) => ({layout}: State): boolean => layout.loadingItems.includes(id),
    location: ({router}: State): Location<unknown> | undefined => opt(router.location).orUndef(),
    locationState: ({router}: State): LocationState | undefined => router.location?.state as LocationState | undefined,
    loadingItems: ({layout}: State): Array<LoadingItemId> => layout.loadingItems,
};
